import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import Services from './Components/Services';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import Home from './Components/Home'; 
import Registration from './Components/Registration';
import PrivacyPolicy from './Components/PrivacyPolicy';

function App() {
  return (
    <div>
     
    <Router>
      <div className="App">
        <Navbar />
        <Routes> 
          <Route path="/" element={<Home />} /> 
          <Route path="/aboutus" element={<AboutUs />} /> 
          <Route path="/services" element={<Services />} /> 
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
    </div>
   
  );
}

export default App;
