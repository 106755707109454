import React from 'react';
import '../css/Footer.css';
import '../images/Footer.jpg';

function Footer(props) {
    return (
        <div className="footer-container">
            <div className="footer-col">
                <h3>Quick Links</h3>

                <a href='/'>Home</a><br /><br />
                <a href='AboutUs'>About Us</a><br /><br />
                <a href='Services'>Services</a><br /><br />
                <a href='ContactUs'>Contact</a>
               

            </div>
            <div className="footer-col">
                <h3>Other Links</h3>
                <a href='PrivacyPolicy'>Privacy Policy</a><br /><br />
                <a href='Registration'>Registration</a>
            </div>
            <div className="footer-col">
                <h3>Contact Us</h3>
                <div className="footer-address">
                    <img src='https://tse2.mm.bing.net/th?id=OIP.fuBvh7YbRNmZ6r1Z3hvuIgHaHa&pid=Api&P=0&h=180' width='30' height='30' alt="Address Icon" />

                    <p>82 King St,Manchester <br />M2 6BA </p>

                    

                </div>
                <div className="footer-phone">
                    <img src='https://tse2.mm.bing.net/th?id=OIP.ATix1J79S58tg6G2FdzPewHaIj&pid=Api&P=0&h=180' width='30' height='30' alt="Phone Icon" />
                    <p>+44 330 133 9678</p>
                </div>
                <div className="footer-email">
                    <img src='https://tse1.mm.bing.net/th?id=OIP.DKnMNGB6q4y3Iqtnt7X5WAHaHa&pid=Api&P=0&h=180' width="30" height="30" alt="Email Icon" />
                    <p>recruitment@careerassure.co.uk</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
