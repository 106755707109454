import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import  '../css/Header.css';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate} from 'react-router-dom';


const Header = () => {
    const navigate = useNavigate();

    const handleRegisterClick = () => {
        navigate('/registration'); 
    };
  return (
    <div>
       <div className="position-relative d-flex justify-content-center px-4 mb-5">
                <img 
                    src='https://jc-supply.co.uk/wp-content/uploads/2022/04/abtt.jpg' 
                    style={{
                      filter: 'blur(1.5px)',
                      height:'500px',
                  }} 
                    className=" img-fluid w-100 h-80 "
                            
                />
                <div class="position-absolute top-50 start-50 translate-middle text-white text-center">
                  <h1 class="fw-bold fs-1 fm-arial mb-3">Welcome To Career Assure!!</h1>
                 
                  <button 
            className="btn btn-lg px-5 fw-semibold fm-arial border border-white" 
            style={{ backgroundColor: 'rgba(197, 21, 21, 0.927)', color: 'white' }}
            onClick={handleRegisterClick}
        >
            Register Here
        </button>
                 
                </div>
                
                
            </div>
    
   
    
            <div className="d-flex justify-content-center mt-5 mb-5">
                <button className="btn btn-lg px-5 shift-button fm-arial fw-semibold " style={{ backgroundColor: 'rgba(197, 21, 21, 0.927)', color: 'white' }}>
                     Apply for a Job Now!
                </button>
            </div>
            <Container>
            <h1 className='text-center fw-bold '>About us</h1>
                <Row> 
                    <Col xs={12} md={6}> 
                        <img 
                            src='https://jc-supply.co.uk/wp-content/uploads/2022/04/abtt.jpg' 
                            
                            className="img-fluid  w-100 h-80 " 
                            
                        />
                    </Col>
                    
                    <Col xs={12} md={6} className="d-flex flex-column justify-content-center">
                        
                       
                        <p className="mb-3 fw-semibold fm-arial fs-5 text-justify">
                            Welcome to Career Assure!

                        </p>
                        <p className="mb-2 fm-arial fs-5 text-justify">
                            At Career Assure, we are dedicated to providing comprehensive solutions that cater to a diverse range of industries, including retail, hospitality, healthcare, IT, and the automobile sector. 
                            With a commitment to excellence, innovation, and client satisfaction, Career Assure is your trusted partner in navigating the complexities of today’s job market and workforce needs. 
                            Let us help you secure the future of your organization with our all-in-one solutions designed to empower businesses across every sector.

                        </p>
                        <div className="d-flex ">
                        <div className="d-flex ">
                          <button className="btn btn-lg px-4 fw-semibold" style={{ backgroundColor: 'rgba(197, 21, 21, 0.927)', color: 'white' }}>
                          <Link to="/aboutus" className="text-white text-decoration-none">Read more</Link>
                          </button>
                        </div>
                    </div>
                    </Col>
                    
                </Row>
            </Container>
            <Container>
      <h1 className='text-center fw-bold mt-5 mb-3 '>Our Services</h1>
      <Row> 
        <Col md={6}>
        
          <div className="position-relative">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNzO5rnhNpLNaDadx0hnBhalbDGI0wE3PdIw&s"
              className="img-fluid w-100 h-100"
              style={{height:'100%'}}
              
            />
            <div className="position-absolute top-50 start-50 translate-middle text-white">
              <h2 className="fw-bold">Talent Aquisition</h2>
              <p className="mb-2  fs-6 text-justify"> Comprehensive recruitment services to source and attract top talent across various sectors.
              <a href="/services" class="text-decoration-none text-primary fw-semibold ">....read more</a>
              </p>
              

            </div>
          </div>
        </Col>
        <Col md={6}>
          
          <div className="position-relative">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDLwPhebWarUskurddGlOpgGi_we6nkuzP6Q&s"
              className="img-fluid w-100 h-100"
              
            />
             <div className="position-absolute top-50 start-50 translate-middle text-white">
              <h2 className="fw-bold">Executive Search</h2>
              <p className="mb-2  fs-6 text-justify">  Executive Search  Specialized search for senior-level executives and leadership roles, ensuring a perfect fit for organizational needs.

              <a href="/services" class="text-decoration-none text-primary fw-semibold ">....read more</a>
              </p>
              

            </div>
          </div>
        </Col>
      </Row>

     
      <div className='d-flex justify-content-center mt-5'>
        <div className="position-relative w-50">
          <img 
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGqII8Re3ygGt9X7f-pt-J4p171KHn0wUUOg&s"
            className="img-fluid w-100 h-100"
            
            
          />
           <div className="position-absolute top-50 start-50 translate-middle text-white">
              <h2 className="fw-bold">Temporary and Contract Staffing</h2>
              <p className="mb-2  fs-6 text-justify">Provision of skilled professionals for short-term projects or seasonal demands, allowing clients to maintain workforce flexibility.


              <a href="/services" class="text-decoration-none text-primary fw-semibold ">....read more</a>
              </p>
              

            </div>
          
        </div>
      </div>
    </Container>

            
           
            
        


    </div>
  );
}

export default Header;