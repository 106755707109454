import React from 'react';
import '../css/AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="header-background-image">
        <h2 className="text-center mb-4">About Us</h2>
      </div>
      <div className="container content">
        <h3>
          Welcome to Career Assure!
        </h3>
        <p>
          At Career Assure, we are dedicated to providing comprehensive solutions that cater to a diverse range of industries, including retail, hospitality, healthcare, IT, and the automobile sector. Our expertise extends beyond these fields to encompass finance, manufacturing, education, logistics, and telecommunications. 
        </p>
        <p>
          We understand that each industry has its unique challenges and requirements, and we pride ourselves on delivering tailored strategies that ensure success. With a commitment to excellence, innovation, and client satisfaction, Career Assure is your trusted partner in navigating the complexities of today’s job market and workforce needs. Let us help you secure the future of your organization with our all-in-one solutions designed to empower businesses across every sector.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
