import React from 'react';
import '../css/Services.css'; 
const servicesData = [
  {
    title: 'Talent Acquisition',
    description: 'Comprehensive recruitment services to source and attract top talent across various sectors.',
  },
  {
    title: 'Executive Search',
    description: 'Specialized search for senior-level executives and leadership roles, ensuring a perfect fit for organizational needs.',
  },
  {
    title: 'Temporary and Contract Staffing',
    description: 'Provision of skilled professionals for short-term projects or seasonal demands, allowing clients to maintain workforce flexibility.',
  },
  {
    title: 'Permanent Placement Services',
    description: 'End-to-end recruitment process for permanent positions, from job profiling to candidate onboarding.',
  },
  {
    title: 'Industry-Specific Recruitment',
    description: 'Tailored recruitment solutions for specific industries, including IT, healthcare, finance, engineering, and more.',
  },
  {
    title: 'Job Market Analysis',
    description: 'Insights and analysis of industry trends, salary benchmarks, and talent availability to help clients make informed hiring decisions.',
  },
  {
    title: 'Employer Branding',
    description: 'Assistance in developing a strong employer brand to attract top talent and enhance company reputation in the job market.',
  },
  {
    title: 'Candidate Screening and Assessment',
    description: 'Rigorous screening processes, including interviews, skills assessments, and background checks to ensure quality candidates.',
  },
  {
    title: 'Talent Management Solutions',
    description: 'Support in workforce planning, employee retention strategies, and succession planning to optimize organizational performance.',
  },
  {
    title: 'Onboarding Support',
    description: 'Comprehensive onboarding services to ensure a smooth transition for new hires, promoting early engagement and productivity.',
  },
  {
    title: 'Consulting Services',
    description: 'Strategic consulting on workforce planning, recruitment strategies, and talent development tailored to organizational goals.',
  },
  {
    title: 'Diversity and Inclusion Initiatives',
    description: 'Commitment to promoting diversity in recruitment practices, helping organizations build inclusive teams.',
  },
  {
    title: 'Training and Development Programs',
    description: 'Offering training for both candidates and clients to enhance skills and improve hiring processes.',
  },
  {
    title: 'Remote and Virtual Recruitment',
    description: 'Specialized services for sourcing and onboarding remote talent, adapting to the changing landscape of work.',
  },
];

const Services = () => {
  return (
    <div className="container my-5">
       <div className="header-background ">
        <h2 className="text-center mb-4">Services </h2>
      </div>
      <div className="row">
        {servicesData.map((service, index) => (
          <div className="col-md-6 mb-4" key={index}>
            <div className="card border-primary">
              <div className="card-body">
                <h5 className="card-title">{service.title}</h5>
                <p className="card-text">{service.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center">
        <h3>Contact Us for More Information</h3>
      </div>
    </div>
  );
};

export default Services;
