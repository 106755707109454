import React from 'react';
import '../css/PrivacyPolicy.css'; 

const PrivacyPolicy = () => {
  return (
    
    <div className="privacypolicy-container">
      <div className="privacypolicy-background-image">
        <h2 className="text-center mb-4">privacypolicy</h2>
      </div>
    <div className="container mt-5">
      <h1 className="text-center mb-4">Privacy Policy</h1>

      <h2>1. Definitions and Interpretation</h2>
      <p>In this privacy policy, the following definitions are used:</p>
      <ul>
        <li><strong>Data:</strong> Collectively all information that you submit to J C Supply Ltd via the Website. This definition incorporates, where applicable, the definitions provided in the Data Protection Act 1998.</li>
        <li><strong>J C Supply Ltd:</strong> A company registered in England and Wales with registered number 11540122 whose registered office is at 26 Wexham Road, Slough, United Kingdom, SL1 1UA.</li>
        <li><strong>User or you:</strong> Any third party that accesses the Website and is not either (i) employed by J C Supply Ltd and acting in the course of their employment or (ii) engaged as a consultant or otherwise providing services to J C Supply Ltd.</li>
        <li><strong>Website:</strong> The website that you are currently using, www.jc-supply.co.uk, and any sub-domains of this site unless expressly excluded by their own terms and conditions.</li>
      </ul>

      <h2>2. Scope of This Privacy Policy</h2>
      <p>This privacy policy applies only to the actions of J C Supply Ltd and Users with respect to this Website. It does not extend to any websites that can be accessed from this Website including, but not limited to, any links we may provide to social media websites.</p>

      <h2>3. Data Collected</h2>
      <p>We may collect the following Data, which includes personal Data, from you:</p>
      <ul>
        <li>Name</li>
        <li>Profession</li>
        <li>Contact information such as email addresses and telephone numbers</li>
        <li>Copy of your CV</li>
        <li>Salary history and preferences</li>
      </ul>

      <h2>4. Our Use of Data</h2>
      <p>For the purposes of the Data Protection Act 1998, J C Supply Ltd is the “data controller.”</p>
      <p>Unless we are obliged or permitted by law to do so, and subject to any third party disclosures specifically set out in this policy, your Data will not be disclosed to third parties.</p>
      <p>Any or all of the above Data may be required by us from time to time in order to provide you with the best possible service and experience when using our Website. Specifically, Data may be used by us for the following reasons:</p>
      <ul>
        <li>Improvement of our products/services</li>
        <li>Transmission by email of promotional materials that may be of interest to you</li>
        <li>Job placement assistance</li>
        <li>Purchase of training courses</li>
        <li>Registration as a staff member or client</li>
      </ul>

      <h2>5. Third-Party Websites and Services</h2>
      <p>J C Supply Ltd may, from time to time, employ the services of other parties for dealing with certain processes necessary for the operation of the Website. The providers of such services have access to certain personal Data provided by Users of this Website.</p>
      <p>Any Data used by such parties is used only to the extent required by them to perform the services that we request. Any use for other purposes is strictly prohibited.</p>

      <h2>6. Links to Other Websites</h2>
      <p>This Website may, from time to time, provide links to other websites. We have no control over such websites and are not responsible for the content of these websites. This privacy policy does not extend to your use of such websites. You are advised to read the privacy policy or statement of other websites prior to using them.</p>

      <h2>7. Changes of Business Ownership and Control</h2>
      <p>J C Supply Ltd may, from time to time, expand or reduce our business and this may involve the sale and/or the transfer of control of all or part of J C Supply Ltd. Data provided by Users will, where it is relevant to any part of our business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this privacy policy, be permitted to use the Data for the purposes for which it was originally supplied to us.</p>

      <h2>8. Controlling Use of Your Data</h2>
      <p>Wherever you are required to submit Data, you will be given options to restrict our use of that Data. This may include the following:</p>
      <ul>
        <li>Use of Data for direct marketing purposes</li>
        <li>Sharing Data with third parties</li>
      </ul>

      <h2>9. Security</h2>
      <p>Data security is of great importance to J C Supply Ltd and to protect your Data we have put in place suitable physical, electronic and managerial procedures to safeguard and secure Data collected via this Website.</p>
      <p>We endeavour to do our best to protect your personal Data. However, transmission of information over the internet is not entirely secure and is done at your own risk. We cannot ensure the security of your Data transmitted to the Website.</p>

      <h2>10. General</h2>
      <p>You may not transfer any of your rights under this privacy policy to any other person. We may transfer our rights under this privacy policy where we reasonably believe your rights will not be affected.</p>
      <p>This Agreement will be governed by and interpreted according to the law of England and Wales. All disputes arising under the Agreement will be subject to the exclusive jurisdiction of the English and Welsh courts.</p>

      <h2>11. Changes to This Privacy Policy</h2>
      <p>J C Supply Ltd reserves the right to change this privacy policy as we may deem necessary from time to time or as may be required by law. Any changes will be immediately posted on the Website and you are deemed to have accepted the terms of the privacy policy on your first use of the Website following the alterations.</p>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
