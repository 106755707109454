import React, { useState } from 'react';
import '../css/Registration.css'; // Your custom CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPhone, faEnvelope, faCity,faAddress, faCode, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';

function Registration() {
    // State to hold form data
    const [formData, setFormData] = useState({
        firstname: '',
        surname: '',
        mobile: '',
        email: '',
        address: '',
        town: '',
        postcode: '',
        niNumber: '',
        jobTitle: '',
        message: ''
    });

    // Handle input change
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value
        }));
    };

    // Function to send email to the user using EmailJS
    const sendEmail = (e) => {
        e.preventDefault();

        // Replace these with your actual EmailJS service ID, template ID, and public key
        const serviceID = 'service_m7xmlcl';
        const templateID = 'template_gh2j1rb';
        const userID = 'MJR_nCM2dYYm-1HCl';

        // Prepare the template parameters
        const templateParams = {
            firstname: formData.firstname,
            surname: formData.surname,
            email: formData.email, // Email address of the user
            mobile: formData.mobile,
            address: formData.address,
            town: formData.town,
            postcode:formData.postcode,
            niNumber: formData.niNumber,
            jobTitle: formData.jobTitle,
            message: formData.message,
        };

        emailjs.send(serviceID, templateID, templateParams, userID)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Registration successful! ');
            })
            .catch((error) => {
                console.error('FAILED...', error);
                alert('Failed to send registration confirmation. Please try again.');
            });
    };

    return (
        <div className="container">
            <div className="r1">
                
                <h1 id="r1">Registration</h1>
            </div>
            <form onSubmit={sendEmail}>
                <h3 align="center">Registration Form</h3>
                <div className="row">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            id="firstname"
                            placeholder="Firstname"
                            required
                            onChange={handleChange}
                        />
                        <FontAwesomeIcon icon={faUser} className="i1" />
                    </div>
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            id="surname"
                            placeholder="Surname"
                            required
                            onChange={handleChange}
                        />
                        <FontAwesomeIcon icon={faUser} className="i1" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Email"
                            required
                            onChange={handleChange}
                        />
                        <FontAwesomeIcon icon={faEnvelope} className="i1" />
                    </div>
                    <div className="col">
                        <input
                            type="number"
                            className="form-control"
                            id="mobile"
                            placeholder="Mobile"
                            required
                            onChange={handleChange}
                        />
                        <FontAwesomeIcon icon={faPhone} className="i1" />
                    </div>
                </div>
                

                <div className="row">
                    <div className="col">
                        <input
                            type="file"
                            className="form-control"
                            id="file"
                            placeholder="Choose File"
                            required
                            onChange={handleChange}
                        />
                    </div>
                </div>
                
                <div className="row">
                    <div className="col">
                        <input
                            type="textarea"
                            className="form-control"
                            id="address"
                            placeholder="Address:"
                            required
                            onChange={handleChange}
                        />
                        <FontAwesomeIcon icon={faAddressBook} className="i1" />
                    </div>
                </div>
                
                {/* Add additional fields here if needed */}
                <div className="row">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            id="town"
                            placeholder="City"
                            required
                            onChange={handleChange}
                        />
                        <FontAwesomeIcon icon={faCity} className="i1" />
                    </div>
                    <div className="col">
                        <input
                            type="number"
                            className="form-control"
                            id="postcode"
                            placeholder="PostCode"
                            required
                            onChange={handleChange}
                        />
                        <FontAwesomeIcon icon={faCode} className="i1" />
                    </div>
                </div>
                
                <div className="row">
                    
                    <div className="col">
                        <input
                            type="number"
                            className="form-control"
                            id="jobTitle"
                            placeholder="Current/most Recent job title"
                            required
                            onChange={handleChange}
                        />
                        
                    </div>
                </div>
                
                

                
                <div className="row">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            id="message"
                            placeholder="Your Message"
                            required
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <br />
                <div>
                    <button className="btn btn-danger" type="submit">Submit</button>
                </div>
            </form>
        </div>
    );
}

export default Registration;
