import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser, faCommentDots, faPhone } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';
import '../css/ContactUs.css';

function ContactUs() {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });
    
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^\d{10}$/; 

        if (!formData.firstname) {
            newErrors.firstname = 'Firstname is required';
        }
        if (!formData.lastname) {
            newErrors.lastname = 'Lastname is required';
        }
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!emailPattern.test(formData.email)) {
            newErrors.email = 'Invalid email format';
        }
        if (!formData.phone) {
            newErrors.phone = 'Phone number is required';
        } else if (!phonePattern.test(formData.phone)) {
            newErrors.phone = 'Phone number must be 10 digits';
        }
        if (!formData.subject) {
            newErrors.subject = 'Subject is required';
        }
        if (!formData.message) {
            newErrors.message = 'Message is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; 
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Replace these with your actual EmailJS service ID, template ID, and public key
            const serviceID = 'service_uj3jicj';
            const templateID = 'template_ekg65lm';
            const userID = 'MJR_nCM2dYYm-1HCl';

            // Prepare the template parameters for EmailJS
            const templateParams = {
                firstname: formData.firstname,
                lastname: formData.lastname,
                email: formData.email, // Email of the user
                phone: formData.phone,
                subject: formData.subject,
                message: formData.message,
            };

            emailjs.send(serviceID, templateID, templateParams, userID)
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    alert('Your message has been sent successfully!');
                    setFormData({
                        firstname: '',
                        lastname: '',
                        email: '',
                        phone: '',
                        subject: '',
                        message: '',
                    });
                    setErrors({});
                })
                .catch((error) => {
                    console.error('FAILED...', error);
                    alert('Failed to send your message. Please try again later.');
                });
        }
    };

    return (
        <Container className="contact-container">
            <h1 className="text-center">Contact Us</h1>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group controlId="id1">
                            <div className="input-group">
                                <Form.Control 
                                    type="text" 
                                    placeholder='Firstname:' 
                                    name="firstname"
                                    value={formData.firstname}
                                    onChange={handleChange}
                                    required
                                />
                                <div className="input-icon">
                                    <FontAwesomeIcon icon={faUser} />
                                </div>
                            </div>
                            {errors.firstname && <div className="text-danger">{errors.firstname}</div>}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="id2">
                            <div className="input-group">
                                <Form.Control 
                                    type="text" 
                                    placeholder='Lastname:' 
                                    name="lastname"
                                    value={formData.lastname}
                                    onChange={handleChange}
                                    required
                                />
                                <div className="input-icon">
                                    <FontAwesomeIcon icon={faUser} />
                                </div>
                            </div>
                            {errors.lastname && <div className="text-danger">{errors.lastname}</div>}
                        </Form.Group>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Form.Group controlId="id3">
                            <div className="input-group">
                                <Form.Control 
                                    type="email" 
                                    placeholder='Email:' 
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                <div className="input-icon">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                            </div>
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="id4">
                            <div className="input-group">
                                <Form.Control 
                                    type="tel" 
                                    placeholder='Number:' 
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                />
                                <div className="input-icon">
                                    <FontAwesomeIcon icon={faPhone} />
                                </div>
                            </div>
                            {errors.phone && <div className="text-danger">{errors.phone}</div>}
                        </Form.Group>
                    </Col>
                </Row>
                <br />
                <Form.Group controlId="id5">
                    <div className="input-group">
                        <Form.Control 
                            type="text" 
                            placeholder='Subject:' 
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                        />
                        <div className="input-icon">
                            <FontAwesomeIcon icon={faCommentDots} />
                        </div>
                    </div>
                    {errors.subject && <div className="text-danger">{errors.subject}</div>}
                </Form.Group>
                <br />
                <Form.Group controlId="id6">
                    <div className="input-group">
                        <Form.Control 
                            as="textarea" 
                            rows={5} 
                            placeholder='Message:' 
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                        <div className="input-icon">
                            <FontAwesomeIcon icon={faCommentDots} />
                        </div>
                    </div>
                    {errors.message && <div className="text-danger">{errors.message}</div>}
                </Form.Group>
                <br />
                <Button variant="danger" id="id7" type="submit">
                    Submit
                </Button>
            </Form>
        </Container>
    );
}

export default ContactUs;
