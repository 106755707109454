import React from 'react';
import '../css/Head.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import logo from '../images/logo.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <div>
      
      <nav className="navbar navbar-expand-lg sticky-top" style={{ backgroundColor: 'rgba(197, 21, 21, 0.927)' }}>
        <div className="container-fluid">
          <div className="navbar-brand text-white d-flex align-items-center">
            <FontAwesomeIcon icon={faPhoneAlt} className="ms-4 me-2" />
            +44 330 133 9678
            <FontAwesomeIcon icon={faEnvelope} className="ms-4 me-2" />
            recruitment@careerassure.co.uk
          </div>
          <div className="ml-auto d-flex align-items-center">
            <a href="https://www.facebook.com/share/shsXoL2YyG1824CF/?mibextid=LQQJ4d" className="icon text-white me-3">
              <FontAwesomeIcon 
                icon={faFacebook} 
                className="rounded-5 fa-2x" 
              />
            </a>
            <a href="https://twitter.com" className="icon text-white me-3">
              <FontAwesomeIcon 
                icon={faTwitter} 
                className="rounded-5 fa-2x" 
              />
            </a>
            <a href="https://instagram.com" className="icon text-white me-3">
              <FontAwesomeIcon 
                icon={faInstagram} 
                className="rounded-5 fa-2x" 
              />
            </a>
          </div>
        </div>
      </nav>

     
      <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top">
        <div className="container-fluid">
        <Link className="navbar-brand" to="/">
        <img 
         src={logo} 
          alt="Azure Career" 
          style={{ width: '200px', height: '100px' }} 
        />
          </Link>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav menu">
              {['Home', 'About Us', 'Services', 'Contact Us'].map((item) => (
                <li className="nav-item" key={item}>
                  <Link className={`nav-link ms-3 me-3${item === 'Home' ? ' active' : ''}`} to={item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '')}`}>
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
